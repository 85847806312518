<script>
import IconBase from "@/components/helpers/IconBase.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: {
    IconBase,
    IconClose,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    showIconClose: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div v-if="showModal" class="main-modal">
      <slot></slot>

      <button
        v-if="showIconClose"
        class="main-modal__close"
        @click="$emit('close')"
      >
        <icon-base
          icon-name="icon-close"
          icon-fill="#ffffff"
          height="32"
          width="32"
          view-box-height="32"
          view-box-width="32"
        >
          <icon-close />
        </icon-base>
      </button>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.main-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;

  &__close {
    right: 24px;
    top: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
}
</style>
