<script>
// TODO: кнопки поверх span
import { mapState, mapActions } from "vuex";
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";
import LessonItem from "@/components/tutorial/LessonItem.vue";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  name: "CoursePage",

  components: {
    MainTitleBack,
    MainSelect,
    LessonItem,
    ButtonRedirect,
  },

  data() {
    return {
      selectedLesson: null,

      selectedOption: null,
    };
  },

  watch: {
    selectedOption(newValue) {
      this.selectedLesson = this.tutorial.data.find((lesson) => {
        return lesson.id === newValue.id;
      });
    },

    selectedLesson() {
      this.selectedOption = this.formattedListOfLessons[
        this.indexOfSelectedLesson
      ];
    },
  },

  computed: {
    ...mapState({
      tutorial: (state) => state.tutorial.tutorial,
    }),

    formattedListOfLessons() {
      return this.tutorial.data.map((lesson, index) => {
        return {
          id: lesson.id,
          name: `${index + 1} - ${lesson.title}`,
        };
      });
    },

    indexOfSelectedLesson() {
      return this.tutorial.data.indexOf(this.selectedLesson);
    },

    textButtonRedirect() {
      return `Следующий урок - ${
        this.tutorial.data[this.indexOfSelectedLesson + 1]?.title
      }`;
    },

    showButtonRedirect() {
      return (
        this.tutorial.data.indexOf(this.selectedLesson) + 1 <
        this.tutorial.data.length
      );
    },

    disabledArrowLeft() {
      return this.indexOfSelectedLesson === 0;
    },

    disabledArrowRight() {
      return this.indexOfSelectedLesson + 1 === this.tutorial.data.length;
    },
  },

  methods: {
    ...mapActions({
      loadTutorial: "tutorial/loadTutorial",
    }),

    changeLesson(indexOfLesson) {
      if (indexOfLesson >= 0 && indexOfLesson < this.tutorial.data.length) {
        this.selectedLesson = this.tutorial.data[indexOfLesson];
      }
    },
  },

  created() {
    const tutorialId = this.$route.params?.id;

    this.loadTutorial(tutorialId)
      .then(() => {
        this.selectedOption = this.formattedListOfLessons[0];
        this.selectedLesson = this.tutorial.data[0];
      })
      .catch(() => {
        this.$router.push("/study");
      });
  },
};
</script>

<template>
  <div v-if="tutorial" class="course-page">
    <main-title-back :title="tutorial.tutorial_title" href="Study" />

    <div class="course-page__lesson-selector">
      <span
        class="course-page__arrow"
        :class="{ 'course-page__arrow_disabled': disabledArrowLeft }"
        @click="changeLesson(indexOfSelectedLesson - 1)"
      ></span>

      <main-select
        :selected-option.sync="selectedOption"
        :options="formattedListOfLessons"
        style-type="background-white"
      />

      <span
        class="course-page__arrow"
        :class="{ 'course-page__arrow_disabled': disabledArrowRight }"
        @click="changeLesson(indexOfSelectedLesson + 1)"
      ></span>
    </div>

    <lesson-item
      v-if="selectedLesson"
      :lesson="selectedLesson"
      :index-of-lesson="indexOfSelectedLesson"
      :count-of-lessons="tutorial.data.length"
    />

    <button-redirect
      v-if="showButtonRedirect"
      :text="textButtonRedirect"
      arrow-position="right"
      @click="changeLesson(indexOfSelectedLesson + 1)"
    />

    <p v-else class="course-page__text">
      Вы прошли курс «{{ tutorial.tutorial_title }}!»
    </p>
  </div>
</template>

<style lang="scss">
.course-page {
  padding: $space-xxl $space-xxl $space-xxl;

  @media (max-width: 768px) {
    padding: $space-xxl $space-l $space-xxxl;
  }

  @media (max-width: 374px) {
    padding: $space-xxl $space-m $space-xxxl;
  }

  &__lesson-selector {
    max-width: 720px;
    margin-bottom: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr 16px;
    column-gap: $space-l;

    .main-select {
      &__items {
        max-width: 100% !important;
      }

      &_background-white {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__arrow {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $dark-fourth;
    mask: url("../assets/icons/arrow_bold.svg");
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    &:first-child {
      transform: rotate(180deg);
    }

    &_disabled {
      cursor: not-allowed;
      background: $light-sixth;

      &:hover,
      &:active {
        background: $light-sixth !important;
      }
    }

    &:hover {
      background: $dark-primary;
    }

    &:active {
      background: $dark-fourth;
    }
  }

  &__text {
    margin-top: 40px;
    @include body-1;
    color: $dark-sixth;
  }
}
</style>
