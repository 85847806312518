<script>
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import MainModalImages from "@/components/helpers/MainModalImages.vue";
import MainDocument from "@/components/helpers/MainDocument.vue";

export default {
  components: {
    videoPlayer,
    MainModalImages,
    MainDocument,
  },

  props: {
    lesson: {
      type: Object,
      required: true,
    },

    indexOfLesson: {
      type: Number,
      required: true,
    },

    countOfLessons: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showModalImages: false,
      selectedImage: null,

      playerOptions: {
        height: "405",
        muted: true,
        language: "ru",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: null,
          },
        ],
      },
    };
  },

  watch: {
    lesson() {
      if (this.lesson?.video) {
        this.playerOptions.sources[0].src = this.lesson.video.url;
      }
    },
  },

  computed: {
    formattedIndexLesson() {
      return `Урок ${this.indexOfLesson + 1}/${this.countOfLessons}`;
    },
  },

  methods: {
    selectImage(image) {
      this.selectedImage = image;

      this.showModalImages = true;
    },
  },

  beforeMount() {
    if (this.lesson?.video) {
      this.playerOptions.sources[0].src = this.lesson.video.url;
    }
  },
};
</script>

<template>
  <div class="lesson-item">
    <div class="lesson-item__title">
      <span>{{ formattedIndexLesson }}</span>
      <p>{{ lesson.title }}</p>
    </div>

    <div v-if="lesson.video" class="lesson-item__video">
      <video-player
        ref="videoPlayer"
        class="video-player-box"
        :options="playerOptions"
        :playsinline="true"
      >
      </video-player>
    </div>

    <div
      class="ql-editor lesson-item__content"
      :class="{ 'lesson-item__content_without-raduis': lesson.images.length }"
      v-html="lesson.content"
    ></div>

    <div v-if="lesson.images.length" class="lesson-item__images">
      <div
        v-for="image in lesson.images"
        :key="image.id"
        class="lesson-item__image"
        @click="selectImage(image)"
      >
        <img :src="image.url" alt="" />
      </div>
    </div>

    <div v-if="lesson.documents.length" class="lesson-item__attachments">
      <p>Документы к уроку</p>

      <main-document
        v-for="document in lesson.documents"
        :key="document.id"
        :title="document.original_name"
        :description="document.description"
        :url="document.url"
      />
    </div>

    <main-modal-images
      v-if="showModalImages"
      :selected-image="selectedImage"
      :images="lesson.images"
      @close="showModalImages = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.lesson-item {
  max-width: 720px;

  &__title {
    padding: $space-xl;
    background-color: $light-primary;
    border-radius: $space-m;
    margin-bottom: $space-m;

    span {
      @include text-2;
      color: $dark-fifth;
      margin-bottom: $space-xs;
    }

    p {
      @include body-1;
      color: $dark-primary;
    }
  }

  &__video {
    height: 405px;
    margin-bottom: $space-m;
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    padding: 40px 40px 32px;
    background-color: $light-primary;
    border-radius: $space-m;

    &_without-radius {
      border-radius: $space-m $space-m 0 0;
    }
  }

  ::v-deep img {
    width: 100%;
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    column-gap: 12px;
    row-gap: 12px;
    border-radius: 0 0 $space-m $space-m;
    background-color: $light-primary;
    padding: 0 40px 40px;
    margin-bottom: $space-m;
  }

  &__image {
    margin: 0 $space-s 0 0;
    width: 130px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;
    background-color: $background-grey;
    cursor: pointer;

    > img {
      width: 100%;
    }
  }

  &__attachments {
    padding: 40px;
    background-color: $light-primary;
    border-radius: $space-m;
    margin-top: $space-m;

    p {
      @include body-1;
      color: $dark-fifth;
      margin-bottom: $space-xl;
    }
  }
}
</style>
