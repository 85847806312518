<script>
import vClickOutside from "v-click-outside";
import MainModal from "@/components/helpers/MainModal.vue";

export default {
  components: {
    MainModal,
  },

  props: {
    selectedImage: {
      type: Object,
      required: true,
    },

    images: {
      type: Array,
      required: true,
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      currentImage: null,
    };
  },

  computed: {
    showButtonPrev() {
      return this.images.indexOf(this.currentImage) > 0;
    },

    showButtonNext() {
      return this.images.indexOf(this.currentImage) + 1 < this.images.length;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    changeImage(direction) {
      const array = this.images;
      const index = array.indexOf(this.currentImage);

      switch (direction) {
        case "next":
          if (index + 1 < array.length) {
            this.currentImage = array[index + 1];
          }

          break;

        case "previous":
          if (index - 1 >= 0) {
            this.currentImage = array[index - 1];
          }

          break;
      }
    },
  },

  beforeMount() {
    this.currentImage = this.selectedImage;
  },
};
</script>

<template>
  <main-modal class="main-modal-images" :show-modal="true" @close="close">
    <div class="main-modal-images__wrapper">
      <div v-click-outside="close" class="main-modal-images__image">
        <button
          v-if="showButtonPrev"
          class="main-modal-images__button main-modal-images__button_prev"
          @click="changeImage('previous')"
        >
          <span></span>
        </button>

        <img :src="currentImage.url" alt="" />

        <button
          v-if="showButtonNext"
          class="main-modal-images__button main-modal-images__button_next"
          @click="changeImage('next')"
        >
          <span></span>
        </button>
      </div>
    </div>
  </main-modal>
</template>

<style lang="scss">
.main-modal-images {
  padding: 0;

  img {
    max-width: 80vw;
    max-height: 80dvh;
    border-radius: 16px;
    z-index: 999;
  }

  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    &_next,
    &_prev {
      width: 96px;
      height: 232px;
      top: calc(50% - 122px);

      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background: $light-fifth;
        mask: url("../../assets/icons/arrow_outline.svg");
        -webkit-mask-size: cover;
        mask-size: cover;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }

      &:hover {
        span {
          background: $light-primary;
        }
      }

      &:active {
        span {
          background: $light-fifth;
        }
      }
    }

    &_next {
      right: 0;
    }

    &_prev {
      left: 0;

      span {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
